import { isString } from 'lodash';
import { useSession } from 'next-auth/react';

import { useGetUserPermissionsQuery } from 'codegen-artifacts/generated/mcng/user';
import sentry from '@/src/lib/initSentry';
import { ApolloError } from '@apollo/client';

type UserPermissions = string[];

const POLL_INTERVAL = 1000 * 60; // Poll permissions every 1 minute

const useUserPermissions = (): [
  UserPermissions,
  boolean,
  ApolloError | undefined,
] => {
  const { data: session } = useSession();

  const auth0UserId = session?.user?.id;

  const { data, loading, error } = useGetUserPermissionsQuery({
    skip: !auth0UserId,
    variables: {
      auth0UserId: auth0UserId || '',
    },
    pollInterval: POLL_INTERVAL,
  });

  if (error) {
    console.error(
      'Can not fetch user permissions, falling back to no permissions.',
    );
    console.log(error);
    sentry.captureEvent(error);
  }

  if (data && !loading && !error) {
    const userPermissions = data.permissions
      .map((permission) => permission.permission)
      .filter(isString);
    return [userPermissions, loading, error];
  }

  return [[], loading, error];
};

export default useUserPermissions;
