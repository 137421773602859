import { signOut, getSession } from 'next-auth/react';
import { ApolloClient, ApolloLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';

import Sentry from '@/src/lib/initSentry';
import {
  HASURA_CLIENT_NAME,
  CALLBACK_URL,
} from '@/src/shared/config/constants';

// TODO: Evaluate if initialState and ctx should be typed
export default function createApolloClient({
  initialState,
  ctx,
}: {
  initialState: any;
  ctx: any;
}) {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  const wsLink = new WebSocketLink({
    uri: (process.env as any).GRAPHQL_HASURA_WS_API,
    options: {
      reconnect: true,
      connectionParams: async () => {
        const session = await getSession();
        const accessToken = session?.accessToken;

        const Authorization = `Bearer ${accessToken}`;
        return {
          headers: {
            Authorization,
            'hasura-client-name': HASURA_CLIENT_NAME,
          },
        };
      },
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        const errorCode = extensions?.code;
        const errorMessage = `[GraphQL error]: Message: [${errorCode}] ${message}, Location: ${locations}, Path: ${path}`;
        return Sentry.captureException(errorMessage);
      });
    }
    if (networkError) {
      const networkErrorMessage = `[Network error]: ${networkError.message}`;
      Sentry.captureException(networkErrorMessage);

      if (networkErrorMessage.includes('Could not verify JWT')) {
        signOut({
          callbackUrl: CALLBACK_URL,
        });
      }
    }
  });

  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: ApolloLink.from([errorLink, wsLink]),
    cache: new InMemoryCache().restore(initialState),
  });
}
